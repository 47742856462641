<template>
	<!-- SideBar -->
	<v-navigation-drawer app light expand-on-hover fixed style="z-index:4001; background-color: var(--background);">
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-menu</v-icon>
			</v-list-item-icon>
			<v-list-item-title class="title">
				Menu
			</v-list-item-title>
		</v-list-item>
		<v-list-item two-line >
			<v-avatar color="brown" size="35" >
                <span class="white--text">{{ user.initials }}</span>
              </v-avatar>
          <v-list-item-content style="padding-left: 15px">
            <v-list-item-title>{{ user.fullName }}</v-list-item-title>
            <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

		<v-divider></v-divider>

		<v-list>
			<v-list-item 
				v-for="item in items" 
				:key="item.title" 
				:to="item.route" 
				link
			>
				<v-list-item-icon>
					<v-icon>{{ item.icon }}</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<template v-slot:append>
			<v-list>
				<v-list-item link to="/account">
					<v-list-item-icon>
						<v-icon>mdi-account</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title >my account</v-list-item-title>
					</v-list-item-content>
				</v-list-item >
				<v-list-item link v-if="user.admin == 'true'" @click="showDialog = true">
					<v-list-item-icon>
						<v-icon>mdi-gavel</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Access to Admin</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item link @click.prevent="Disconnect()">
					<v-list-item-icon>
						<v-icon>mdi-logout</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title >LOGOUT</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</template>

		<v-row justify="center">
			<v-dialog v-model="showDialog" persistent max-width="600px">
			<v-card>
				<v-card-title>
				<span class="text-h5">Password</span>
				</v-card-title>
				<v-card-text>
					<v-row>
					<v-col cols="12">
						<v-text-field
						label="Password*"
						type="password"
						v-model="passwordInput"
						required
						@keyup.enter="checkPassword"
						></v-text-field>
					</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="showDialog = false" >
					Close
				</v-btn>
				<v-btn color="blue darken-1" text @click.prevent="checkPassword()" >
					Save
				</v-btn>
				</v-card-actions>
			</v-card>
			</v-dialog>
		</v-row>

</v-navigation-drawer>
</template>


<script>
import { switchToken } from '../functions/test-token';
import Vue from 'vue';
import Vuetify from 'vuetify';
import bcrypt from 'bcryptjs';

Vue.use(Vuetify);

	export default {
		name: 'SideBar',
		data() {
			return {
				showDialog: false,
				passwordInput: '',
				user: {
					initials: 'JD',
					fullName: 'John Doe',
					email: 'john.doe@doe.com',
					admin: ''
				},
				items: [
					{
						title: 'Dashboard',
						icon: 'mdi-table-large',
						route: '/home'
					},
					{
						title: 'Export',
						icon: 'mdi-file-table-outline',
						route: '/tableau/tags',
					},
					
					{
						title: 'Live Data',
						icon: 'mdi-chart-bell-curve',
						route: '/courbes-matrices-tag'
					},
					{
						title: 'Live Matrice',
						icon: 'mdi-office-building',
						route: '/matrice'
					},
					{
						title: 'Experiences',
						icon: 'mdi-creation',
						route: '/experience'
					},
				],
				right: null,
			}
		},
		mounted(){
			this.getInfoUser();
		},
		methods:{
			async getInfoUser(){
				const user = JSON.parse(localStorage.getItem('user'))
				this.user.initials = user.username.substr(0,2)
				this.user.fullName = user.username
				this.user.email = user.email
				this.user.admin = user.admin
			},
			async Disconnect (){
				await this.supressSession();
				localStorage.clear();
				
				this.user.initials = ''
				this.user.fullName = 'disconnected'
				this.user.email = ''
				this.$router.push({name:'Login'})
			},

			async supressSession(){
				const user = JSON.parse(localStorage.getItem('user'))
				const url = this.$api.getRESTApiUri() + `/user/delete-session`;
				return fetch(url, {
						method: 'PUT',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							email: user.email,
						}),
				})
				.then(res => res.text())
				.then((result) => {
					const data = JSON.parse(result);
				});	
			},

			async ToAdmin (){
				this.dialog = true;
				this.btn_show = true;
			},
			async checkPassword(){
				const user = JSON.parse(localStorage.getItem('user'))
				const accessToken = localStorage.getItem('accessToken');
				const email = user.email
				const url = this.$api.getRESTApiUri() + `/users/email/${email}`;
				const headers = new Headers({
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${accessToken}`
				});
				return fetch(url, { headers: headers })
					.then(res => {
					if (res.status === 401) {
						switchToken().then(() => {
						// Refaites la requête avec le nouveau token
							this.checkPassword();
						});
					}
					return res.text();
					})
				.then(async (result) => {
					const data = JSON.parse(result);
					this.passwordDatabase = data.password;
					let isAuthenticated = await Promise.all([this.TestPassword()]);
					if(isAuthenticated == 'true'){
					const accessAdmin = 'true'
					localStorage.setItem('accessAdmin', accessAdmin)
					this.$router.push({name:'liste des utilisateurs'})
					this.showDialog = false;
					}else{
						window.alert('Passwords do not match please try again')
					}
				})
				.catch(error => {
					console.log(error)
				});
			},
			async TestPassword(){
				const compare = await bcrypt.compareSync(this.passwordInput, this.passwordDatabase);
				console.log(compare)
				return String(compare);
			},
					
			async toAccount(){
				this.$router.push({name:'my account'})
			}
		}
	};

	
</script>

<style>
  .avatar-sm {
    width: 25px;
    height: 25px;
  }

  .text-xs {
    font-size: 10px;
  }
</style>