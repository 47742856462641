import { Line } from "vue-chartjs";
import 'chartjs-plugin-streaming';

export default {
	extends: Line,
	props: {
		label: {
			type: String
		},
		chartData: {
			type: Array
		},
		options: {
			type: Object
		},
		chartColors: {
			type: Array
		},
		chartColorsImplant: {
			type: Array
		},
		tag_id:{
			type: Array
		},
		tag_id_name:{
			type: Array
		},
		pause:{
			type: Boolean
		},
		vib:{
			type: Boolean
		}
	},
	data() {
		return {
			chartInstance: null, // Ajouter cette ligne pour stocker l'instance du graphique
		};
	},
	mounted() {
		const datasets = [];
		const tag_id_implant = this.tag_id.filter((element) => element.startsWith('imp') || element.startsWith('ppg1'));
		const tag_id_capteur = this.tag_id.filter((element) => element.startsWith('env') || element.startsWith('vib') || element.startsWith('spha'));
		
		let tension;
		let pointRadius;
		let borderWidth;
		if(this.vib == true){
			tension= 0.1;
			pointRadius = 1.0;
			borderWidth = 1.0;
		}else{
			tension= 0.2;
			pointRadius = 3.0;
			borderWidth = 2.0;
		}
		console.log(this.tag_id);
		for(let i =0; i < tag_id_implant.length; i++){
			console.log(this.tag_id_name[this.tag_id.indexOf(tag_id_implant[i])])
			datasets.push({
				label: this.tag_id_name[this.tag_id.indexOf(tag_id_implant[i])],
				data: [],
				fill: false,
				borderColor: this.chartColorsImplant[i].borderColor,
				backgroundColor: this.chartColorsImplant[i].backgroundColor,
				tension: tension,
				pointRadius: pointRadius,
				borderWidth: borderWidth,
			})
		}
		for(let i = 0; i < tag_id_capteur.length; i++){
			datasets.push({
				label: this.tag_id_name[this.tag_id.indexOf(tag_id_capteur[i])],
				data: [],
				fill: false,
				borderColor: this.chartColors[i].borderColor,
				backgroundColor: this.chartColors[i].backgroundColor,
				tension: tension,
				pointRadius: pointRadius,
				borderWidth: borderWidth,
			})
		}
		const chartOptions = {
			...this.options,
				plugins: {
					streaming: {
						...this.options.plugins.streaming,
						pause: this.pause,
					},
				},
			};
			this.renderChart({ datasets: datasets }, chartOptions);
			this.chartInstance = this.$data._chart;
	},
	  watch: {
		pause(newPause) {
		// Mettre à jour la valeur de l'option "pause" et mettre à jour le graphique
		this.$data._chart.config.options.plugins.streaming.pause = newPause;
		this.$data._chart.update();
		}
	},
};