import { Line } from "vue-chartjs";
import 'chartjs-plugin-streaming';

export default {
	extends: Line,
	props: {
		label: {
			type: String
		},
		chartData: {
			type: Array
		},
		options: {
			type: Object
		},
		chartColors: {
			type: Array 
		},
		chartColorsImplant: {
			type: Object
		},
		tag_id_name:{
			type: Array
		},
		tag_id:{
			type: Array
		},
		pause:{
			type: Boolean
		}
	},
	data() {
		return {
		chartInstance: null, // Ajouter cette ligne pour stocker l'instance du graphique
		};
	},
	mounted() {
		const datasets = [];
		for(let i =0; i < this.tag_id_name.length; i++){
			if(this.tag_id[i].startsWith('imp')){
				datasets.push({
					label: this.tag_id_name[i],
					data: [],
					fill: false,
					borderColor: this.chartColorsImplant[i].borderColor,
					backgroundColor: this.chartColorsImplant[i].backgroundColor,
					tension: 0.2,
					pointRadius: 2.0,
					borderWidth: 2.0,
				})
			}else{
				datasets.push({
					label: this.tag_id_name[i],
					data: [],
					fill: false,
					borderColor: this.chartColors[i].borderColor,
					backgroundColor: this.chartColors[i].backgroundColor,
					tension: 0.2,
					pointRadius: 2.0,
					borderWidth: 2.0,
				})
			}
		}

		const chartOptions = {
			...this.options,
			plugins: {
				streaming: {
				...this.options.plugins.streaming,
				pause: this.pause,
				},
			},
			};

			this.renderChart({
			datasets: datasets
			}, chartOptions);
			this.chartInstance = this.$data._chart;
	},
	  watch: {
		pause(newPause) {
		// Mettre à jour la valeur de l'option "pause" et mettre à jour le graphique
		this.$data._chart.config.options.plugins.streaming.pause = newPause;
		this.$data._chart.update();
		}
	},
};